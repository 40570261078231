export class SAPConnection {
    constructor(
        public Id: number,
        public Server: string,
        public LicenseServer: string,
        public BoSuppLangs: string,
        public DST: string,
        public DBUser: string,
        public DBPass: string,
        public UseTrusted: boolean,
    ) { }
}
