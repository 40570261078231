import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

// MODELOS
import { PasswordValidation } from './../../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import { StorageService, AlertService } from '../../services/index';
import { AuthenticationService } from './../../services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  userForm: FormGroup; // variable del formulario, tanto de login como de signin
  submitted = false;
  returnUrl: string;
  isLogin: boolean; // variable para saber si esta activo el formde login (true) o de signin (false)
  forgotPwsd: boolean;

  constructor( private fb: FormBuilder,
               private route: ActivatedRoute,
               private router: Router,
               private auth: AuthenticationService,
               private storageService: StorageService,
               private alertService: AlertService
  ) {
    console.log('constructor LoginComponent');
    // redirect to home if already logged in
    if (this.auth.currentUserValue) {
      this.router.navigate(['/']);
    }

  }

  ngOnInit() {

    this.isLogin = true;
    // se activa el form de login
    this.userForm = this.fb.group({
      email: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      password: ['', Validators.required]
    });
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get fUser() { return this.userForm.controls; }

  // funcion para el envio del formulario, tanto de login como de signin
  onSubmit() {
    console.log("submit-------");
      this.submitted = true;
      // stop here if form is invalid
      if (this.userForm.invalid) {
        this.alertService.errorAlert('El formulario contiene errores');
      }
      // consulta a la DB para el login del user
      if (this.isLogin && !this.forgotPwsd) {
        this.blockUI.start('Validando Ingreso, Espere Por Favor...'); // Start blocking
        this.auth.login(this.fUser.email.value, this.fUser.password.value) // this.auth.getFEToken()
        .pipe(first())
        .subscribe(
          data => {
            console.log('data login');
            //console.log(data);
            this.blockUI.stop(); // Stop blocking
            this.alertService.successInfoAlert('Logueado Correctamente');
            this.router.navigate([this.returnUrl]);
          },
          error => {
            this.alertService.errorAlert(`Error: ${error}`);
            this.blockUI.stop(); // Stop blocking
          });
        // consulta a la DB para la creacion de un usuario
       } else if (this.forgotPwsd ) {
        this.blockUI.start('Enviando información al correo electrónico!!!'); // Start blocking
        this.auth.sendRecoverPswdEmail(this.userForm)
        .subscribe( (data: any) => {
          if (data.result) {
            this.blockUI.stop(); // Stop blocking
            this.alertService.successInfoAlert('Correo Enviado Correctamente !!!');
          } else {
            this.blockUI.stop(); // Stop blocking
            this.alertService.errorAlert(`Error al Recuperar cuenta, Codigo: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`);
          }
        }, error => {
          this.blockUI.stop(); // Stop blocking
          this.alertService.errorAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
        });
      } //else if (!this.isLogin && !this.forgotPwsd) {
      //   if (this.checkPasswords(this.userForm)) {
      //     this.blockUI.start('Registrando Usuario, Espere Por Favor...'); // Start blocking
      //     this.auth.signin(this.userForm);
      //   } else {
      //     this.blockUI.stop(); // Stop blocking
      //     this.alertService.errorAlert('Datos Incorrectos, Las contraseñas no coinciden!!!');
      //   }
      // }
  }

  /* Funcion para cambiar entre el formulario de login y de sigin*/
  clickEvent(islogin: boolean, forgotPwsd: boolean) {
   this.isLogin = islogin;
   this.forgotPwsd = forgotPwsd;
   this.userForm.reset;
   // se activa el form de login
   if (this.isLogin && !this.forgotPwsd) {
     this.userForm = this.fb.group({
      email: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      password: ['', Validators.required]
     });
   // se activa el form de registrarse
   } else if (!this.isLogin && !this.forgotPwsd) {
     this.userForm = this.fb.group({
      fullName: ['', Validators.required],
      email: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      identification: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    }, {
      validator: PasswordValidation.MatchPassword // your validation method
     });
   // se activa el form de recuperar la contrasenna
   } else if (this.forgotPwsd) {
     this.userForm = this.fb.group({
       email: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]]
     });
   }
   // get return url from route parameters or default to '/'
   this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // funcion para validar el que las contraseñas sean iguales al registrar un usuario nuevo
  checkPasswords(group: FormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;
    return pass === confirmPass ? true : false;
  }

}