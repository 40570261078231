import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// Servicios
import { BusinesspartnerService } from '../../services/businesspartner.service';

// Modelos
import { BusinessPartnerSearch } from '../../models/bpsearch';

// Componentes
import { BusinesspartnersearchComponent } from '../businesspartnersearch/businesspartnersearch.component';

@Component({
  selector: 'app-businesspartner',
  templateUrl: './businesspartner.component.html',
  styleUrls: ['./businesspartner.component.css']
})
export class BusinesspartnerComponent implements OnInit {

  @ViewChild('bpSearch') childOne: BusinesspartnersearchComponent;
  businessPartnerList: any[] = [];
  bpSearch: BusinessPartnerSearch;
  bpHaveData: boolean;
  bpMessage: string;

  //Modal
  RJResponse: string;
  RJResponseDate: string;
  RJStatusDetails: string; 
  ClavisResponse: string;
  ClavisResponseDate: string;
  SendDetails: JSON;

  constructor( private businesspartner: BusinesspartnerService, private modalService: NgbModal ) {
    console.log('constructor BusinesspartnerComponent');
  }

  ngOnInit() {
    console.log('ngOnInit BusinesspartnerComponent');
    this.childOne.emitEvent
    .subscribe(
      res => {
        this.bpSearch = res;
        console.log('bpSearch:');
        console.log(this.bpSearch);
        this.getBP();
      }
    );
  }

  // metodo para cuando se cambie en el componente hijo (BusinesspartnersearchComponent)
  change(): void {
    this.childOne.getBP();
  }

  // metodo para obtener los socios de negocios
  getBP() {
    this.businesspartner.getBusinessPartner(this.bpSearch)
    .subscribe( (data: any) => {
      console.log(data);
      console.log(data.result);
      this.bpHaveData = data.result;
      if (data.result) {
        console.log('t');
        this.businessPartnerList = data.BussinessPartnersList;
      } else {
        console.log('f');
        this.bpMessage = data.errorInfo.Message;
      }
    });
  }

  ViewBPData(RJResponse: string, RJResponseDate: string, RJStatusDetails: string, ClavisResponse: string, ClavisResponseDate: string, contentBP){
    console.log("Modal");
    this.RJResponse = RJResponse;
    this.RJResponseDate = RJResponseDate;
    this.RJStatusDetails = RJStatusDetails;
    this.ClavisResponse = ClavisResponse;
    this.ClavisResponseDate = ClavisResponseDate;
    this.modalService.open(contentBP, { centered: true, size: 'lg' });
  }

  ViewDocDataInfo(Details: string, contentDocinfo){
    this.SendDetails = JSON.parse( Details);
    this.modalService.open(contentDocinfo, { centered: true, size: 'lg'  });
  }
}
