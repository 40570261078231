export enum DocType {
    Factura = 13,
    NotaCredito = 14,
    NotaDebito = 99
}

export enum BPType {
    Customer = 1,
    supplier = 2
}

