import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private localStorageService;

  constructor() {
    this.localStorageService = localStorage;
  }
  // coloca los datos de la sesion actual, token y expiracion, usuario logueado
  setCurrentSession(currentUser): void {
    this.localStorageService.setItem('currentUser', JSON.stringify(currentUser));
  }
  // remueve la sesion actual
  removeCurrentSession(): void {
    this.localStorageService.removeItem('currentUser');
  }
  // obtiene el token de la sesion actual
  getCurrentSession(): string {
    return this.localStorageService.getItem('currentUser');
  }
  // coloca los datos de la sesion actual, token y expiracion, usuario logueado
  setSelectedCompany(companyName, companyId): void {
    const SelectedCompany = {
      'companyName': companyName,
      'companyId': companyId
    };
    this.localStorageService.setItem('SelectedCompany', JSON.stringify(SelectedCompany));
  }
  // remueve la sesion actual
  removeSelectedCompany(): void {
    this.localStorageService.removeItem('SelectedCompany');
  }
  // obtiene el token de la sesion actual
  getSelectedCompany(): string {
    return this.localStorageService.getItem('SelectedCompany');
  }

  setCurrentPass(Password): void {
    this.localStorageService.setItem('Password', JSON.stringify(Password));
  }
  // remueve la sesion actual
  removeCurrentPass(): void {
    this.localStorageService.removeItem('Password');
  }
  // obtiene el token de la sesion actual
  getCurrentPass(): string {
    return this.localStorageService.getItem('Password');
  }

  setCurrentUserName(UserName): void {
    this.localStorageService.setItem('UserName', JSON.stringify(UserName));
  }
  // remueve la sesion actual
  removeCurrentUserName(): void {
    this.localStorageService.removeItem('UserName');
  }
  // obtiene el token de la sesion actual
  getCurrentUserName(): string {
    return this.localStorageService.getItem('UserName');
  }

}