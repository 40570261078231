import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DocumentsSearch } from '../models/documentsearch';
import { AppConstants } from '../models';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private http: HttpClient) {
    console.log('DocumentService listo');
  }

  getQuery( query: DocumentsSearch ) {
    //const URL = `https://misadapitesting.clavisco.com/api/Documents/SearchDocuments/`;
    const URL =`${AppConstants.apiUrl}api/Documents/SearchDocuments/`
    return this.http.post(URL, query);
  }

  getDocuments(docSearch: DocumentsSearch) {

    return this.getQuery(docSearch);

    // return this.getQuery('browse/new-releases?limit=20')
      // .pipe( map( data => data['albums'].items ));

  }

}
