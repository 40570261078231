import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy} from '@angular/common';

import { FormsModule } from '@angular/forms';
import { BlockUIModule } from 'ng-block-ui';
import { NgbModule, NgbPaginationModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularFontAwesomeModule } from 'angular-font-awesome';

// Rutas
import { APP_ROUTING } from './app.routes';

// Servicios
import { AuthenticationService } from './services/authentication.service';
import { AuthGuard } from './services/auth.guard';
import { ErrorInterceptor} from './services/errors.interceptor';
// Componentes
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { DocumentComponent } from './components/document/document.component';
import { BusinesspartnerComponent } from './components/businesspartner/businesspartner.component';
import { SapconfigComponent } from './components/sapconfig/sapconfig.component';
import { CompanyconfigComponent } from './components/companyconfig/companyconfig.component';
import { HomeComponent } from './components/home/home.component';
import { DocumentsearchComponent } from './components/documentsearch/documentsearch.component';
import { BusinesspartnersearchComponent } from './components/businesspartnersearch/businesspartnersearch.component';
import { ModalComponent } from './components/modal/modal.component';
import { PasswordPipe } from './pipes/password.pipe';
import { LoginComponent } from './components/login/login.component';
import { BlockUIComponent } from 'ng-block-ui';
import { from } from 'rxjs';
import { RecoverEmailComponent } from './components/recover-email/recover-email.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    DocumentComponent,
    BusinesspartnerComponent,
    SapconfigComponent,
    CompanyconfigComponent,
    HomeComponent,
    DocumentsearchComponent,
    BusinesspartnersearchComponent,
    ModalComponent,
    PasswordPipe,
    LoginComponent,
    RecoverEmailComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    NgbPaginationModule,
    NgbAlertModule,
    ReactiveFormsModule,
    APP_ROUTING,
    BlockUIModule.forRoot({
      delayStop: 500
    }),
    AngularFontAwesomeModule
  ],
  providers: [
    AuthenticationService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
