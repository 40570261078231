import { Component, OnInit } from '@angular/core';
import { SapconfigService } from '../../services/sapconfig.service';
import { SAPConnection } from '../../models/sapconnection';

@Component({
  selector: 'app-sapconfig',
  templateUrl: './sapconfig.component.html',
  styleUrls: ['./sapconfig.component.css']
})
export class SapconfigComponent implements OnInit {

  sapConn: SAPConnection;
  sapConnHaveData: boolean;
  sapConnMessage: string;

  constructor( private sapconfig: SapconfigService ) {
    console.log('constructor SapconfigComponent');
    this.sapConn = new SAPConnection(0, '', '', '', '', '', '', true);
  }

  ngOnInit() {
    console.log('ngOnInit SapconfigComponent');
    this.sapconfig.getSapConfigs()
    .subscribe( (data: any) => {
      console.log(data);
      this.sapConnHaveData = data.result;
      console.log(data);
      if (data.result) {
        this.sapConn = data.SAPConn;
      } else {
        this.sapConnMessage = data.errorInfo.Message;
      }
      // this.sapConfigList = data;

    });
  }


}
