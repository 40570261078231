import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import Swal from 'sweetalert2';

// import { AppConstants } from './../models/index';
import { AppConstants } from './../models/index';
import { AlertService } from './alert.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  @BlockUI() blockUI: NgBlockUI;

  // readonly apiUrl = 'http://localhost:50001/';

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor( private http: HttpClient,
               private storage: StorageService,
               private router: Router,
               private alertService: AlertService ) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();//
  }

  public get currentUserValue(): any {
      return this.currentUserSubject.value;
  }

  public deleteCurrentUserValue(): any {
    this.currentUserSubject.next(null);
  }

  // funcion para el logueo del usuario,
  // recibe como parametros el usuario y contrasenna
  login(username: string, password: string) {

    const body = new HttpParams()
    .set('grant_type', 'password')
    .set('username', username)
    .set('password', password)
    .set('is_login', 'true');

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.http.post<any>(`${AppConstants.apiUrl}token`, body.toString(), { headers })
    .pipe(map(user => {
      // login successful if there's a owin token in the response
      if (user && user.access_token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        this.storage.setCurrentSession(user);
        this.storage.setCurrentPass(password);
        this.storage.setSelectedCompany(user.CompanyName, user.companyId);
        this.currentUserSubject.next(user);
      }
      return user;
    }));
  }

  // funcion para el registro del usuario,
  // recibe como parametros el usuario y contrasenna
  signin(user: FormGroup) {
    // console.log(user);
    // const registerUser = new RegisterUser(user.value.password, '', user.value.identification, user.value.fullName, user.value.email, false, '', new Date(), true, '', true);
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json'
    // });

    // this.http.post(`${AppConstants.apiUrl}api/Account/RegisterUser/`,
    // registerUser,
    // { headers } )
    // .subscribe( (data: any) => {
    //   if (data.result) {
    //     this.alertService.successInfoAlert('Usuario Registrado Correctamente');
    //   } else {
    //     this.alertService.warningInfoAlert(`No se Pudo Registrar el Usuario Correctamente!!!, Codigo: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`);
    //   }
    //   this.blockUI.stop(); // Stop blocking
    // }, error => {
    //   this.blockUI.stop(); // Stop blocking
    //   console.log('error');
    //   console.log(error);
    //   this.alertService.errorAlert(`Error al Registrar el Usuario!!!, Error: ${error}`);
    // });
  }

  // metodo para desloguearse, elimina la sesion del localstorage y oculta el sidemenu
  logout() {
    this.storage.removeCurrentSession();
    this.storage.removeSelectedCompany();
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
  }

  // verificacion de correo del usuario
  ConfirmEmail(token, userForm: FormGroup) {
    const confirmEmailIOA = {
      'word': userForm.value.password,
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    return this.http.post(`${AppConstants.apiUrl}api/Account/ConfirmEmail/`,
    confirmEmailIOA,
    { headers }
    );

  }

  sendRecoverPswdEmail(user: FormGroup) {
    console.log(user);
    const userEmail = {
      'HashWord': (user.value.email).toString()
    };

    console.log(userEmail);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(`${AppConstants.apiUrl}api/Account/SendRecoverPswdEmail`,
    userEmail,
    { headers } );
  }

  recoverPswd(user: FormGroup) {
    console.log(user);
    const recoverPswd = {
      'Email': user.value.email,
      'Password': user.value.password
    };
    //console.log(recoverPswd);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(`${AppConstants.apiUrl}api/Account/RecoverPswd`,
    recoverPswd,
    { headers } );
  }
}