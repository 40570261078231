export class DocumentsSearch {
    constructor(
        public StartDate: Date,
        public EndDate: Date,
        public DocType: number, // tipo de documento (ARinvoice 13, APinvoice 18, CreditMemo 14, IncommingPayment 24)
        public Status: number, // tipo de estado (Correcto = 0, Error = 1)
        public DBcode: string,
        public Passwd: string,
        public User: string
    ) { }
}
