import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { DocumentsSearch } from '../../models/documentsearch';
import { FormsModule } from '@angular/forms';
import {StorageService } from '../../services/index';

@Component({
  selector: 'app-documentsearch',
  templateUrl: './documentsearch.component.html',
  styleUrls: ['./documentsearch.component.css']
})
export class DocumentsearchComponent implements OnInit {

  @Output() emitEvent: EventEmitter<DocumentsSearch> = new EventEmitter<DocumentsSearch>();

  endDate: any; // fecha final
  startDate: any; // fecha inicial
  docType: number; // tipo de documento
  docStatus: number; // estado de documento
  DBcode : string;
  User : any;
  Passwd : string;
  currentUser: any;
  SelectedCompany: string;

  // lista con los tipos de documentos
  docTypeList: any[] = [
    {
      type: 13,
      name: 'Factura',
    },
    {
      type: 14,
      name: 'Nota de Crédito',
    },
    // {
    //   type: 99,
    //   name: 'Nota de Débito',
    // }
  ];

  // lista con los estados de documentos
  docStatusList: any[] = [
    {
      type: 0,
      name: 'Correcto',
    },
    {
      type: 2,
      name: 'Ejecutándose',
    },        
    {
      type: 3,
      name: 'Espera SAP',
    },
    {
      type: 1,
      name: 'Error',
    }
  ];

  date: Date = new Date();

  constructor(private storage: StorageService) {
    this.endDate = `${this.date.getFullYear()}-${('0' + (this.date.getMonth() + 1)).slice(-2)}-${('0' + this.date.getDate()).slice(-2)}`;
    this.startDate = `${this.date.getFullYear()}-${('0' + (this.date.getMonth() + 1)).slice(-2)}-${('0' + this.date.getDate()).slice(-2)}`;
    this.docType = this.docTypeList[0].type;
    this.docStatus = this.docStatusList[0].type;
    this.currentUser = JSON.parse(this.storage.getCurrentSession());
    this.User = this.currentUser.UserName;
    this.DBcode = this.currentUser.DbCode
    this.Passwd =JSON.parse(this.storage.getCurrentPass());
  }

  ngOnInit() {
    //this.getDocuments();
  }

  // funcion para obtener los parametros para filtrado de los documentos de la DBLocal
  public getDocuments(): DocumentsSearch {
    debugger;
    let doc = new DocumentsSearch(this.startDate, this.endDate, this.docType, this.docStatus, this.DBcode, this.Passwd, this.User);
    this.emitEvent.emit(doc);
    return doc;
  }

}
