export class AppConstants {
    // dev
    // public static get apiUrl(): string { return 'http://localhost:49850/'; }
    // prod
    // public static get apiUrl(): string { return 'https://misinvelapi.clavisco.com/'; }
    // test
    public static get apiUrl(): string { return 'https://misinvelapitest.clavisco.com/'; }
}

export const IdentificationType = [
    {
        'Id': '01',
        'Name': 'Cedula Fisica',
    },
    {
        'Id': '02',
        'Name': 'Cedula Juridica',
    }
];

export const CreditStatusTypes = [
    {
        'Id': '01',
        'Name': 'Análisis',
    },
    {
        'Id': '02',
        'Name': 'Recomendado',
    },
    {
        'Id': '03',
        'Name': 'Aprobado G.Riesgo',
    },
    {
        'Id': '04',
        'Name': 'Aprobado G.General',
    },
    {
        'Id': '05',
        'Name': 'Rechazado',
    },
    {
        'Id': '06',
        'Name': 'Revisar',
    }
];
