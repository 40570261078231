import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './../../../services/authentication.service';
import { Router } from '@angular/router';
import { StorageService} from '../../../services/storage.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  currenUser: any;
  constructor(private authenticationService: AuthenticationService, private router: Router, private storge: StorageService) { 

    this.authenticationService.currentUser.subscribe(x=> this.currenUser = x)
    
  }

  ngOnInit() {
  }
  
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
