import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// Servicios
import { DocumentService } from '../../services/document.service';
import {StorageService } from '../../services/index'
// Modelos
import { DocumentsSearch } from '../../models/documentsearch';
import { DocType } from '../../enums/enums';
// Componentes
import { DocumentsearchComponent } from '../documentsearch/documentsearch.component';
import { ComponentFactoryResolver } from '@angular/core/src/render3';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})
export class DocumentComponent implements OnInit {

  @ViewChild('docSearch') childOne: DocumentsearchComponent;
  documentList: any[] = [];
  docSearch: DocumentsSearch;
  docHaveData: boolean;
  docMessage: string;

  docArInv: number;
  docCredit: number;
  docNotDeb: number;
  doctype: number;

  //Informacion del modal
  CreateDate: string;
  ClavisResponse: string;
  ClavisResponseDate: string;
  currentUser: string;
  
  RJResponse: string;
  RJResponseDate: string;
  RJStatusDetails: string;
  SelectedCompany: string;

  SendAndReciveDetails: string;

  constructor( private document: DocumentService, private modalService: NgbModal, private storage: StorageService
   ) {
    console.log('constructor DocumentComponent');
  }

  ngOnInit() {
   debugger;
    this.doctype = 0;
    this.currentUser = JSON.parse(this.storage.getCurrentSession());
    console.log("currentuser------------------------"+this.currentUser)
    this.SelectedCompany = JSON.parse(this.storage.getSelectedCompany());
    console.log('ngOnInit DocumentComponent');
    this.childOne.emitEvent
    .subscribe(
      res => {
        this.docSearch = res;
        console.log('docSearch:');
        console.log(this.docSearch);
        this.getDoc();
        this.doctype = this.docSearch.DocType;
        this.docArInv = DocType.Factura;
        this.docCredit = DocType.NotaCredito;
        this.docNotDeb = DocType.NotaDebito;
        this.docCredit = DocType.NotaCredito;
        console.log(this.docSearch);
      }
    );
  }

  // metodo para cuando se cambie en el componente hijo (DocumentsearchComponent)
  change(): void {
    this.childOne.getDocuments();
  }

  // metodo para obtener los documentos
  getDoc() { 
    
    this.document.getDocuments(this.docSearch)
    .subscribe( (data: any) => {
      console.log("Docs " + data.DocumentList);
      console.log(data.result);
      this.docHaveData = data.result;
      if (data.result) {
        console.log('t');
        this.documentList = data.DocumentList;
        console.log(this.documentList);
      } else {
        console.log('f');
        this.docMessage = data.errorInfo.Message;
      }
    },(error: any) => {      
      console.error('error');
      console.error(error);
    });
  }

  ViewDocData(CreateDate: string, ClavisResponse: string, ClavisResponseDate: string, RJResponse: string, RJResponseDate: string, RJStatusDetails: string,  contentDoc){
    console.log("Modal");
    this.CreateDate = CreateDate;
    this.ClavisResponse = ClavisResponse;
    this.ClavisResponseDate = ClavisResponseDate;
    this.RJResponse = RJResponse;
    this.RJResponseDate = RJResponseDate;
    this.RJStatusDetails = RJStatusDetails;
    this.modalService.open(contentDoc, { centered: true, size: 'lg' });
  }

  ViewDocDataInfo(Details: string, contentDocinfo){
    this.SendAndReciveDetails = JSON.parse( Details);
    this.modalService.open(contentDocinfo, { centered: true, size: 'lg'  });
  }

}
