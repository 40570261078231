import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { BusinessPartnerSearch } from '../../models/bpsearch';
import { FormsModule } from '@angular/forms';
import {StorageService } from '../../services/index';
@Component({
  selector: 'app-businesspartnersearch',
  templateUrl: './businesspartnersearch.component.html',
  styleUrls: ['./businesspartnersearch.component.css']
})
export class BusinesspartnersearchComponent implements OnInit {

  @Output() emitEvent: EventEmitter<BusinessPartnerSearch> = new EventEmitter<BusinessPartnerSearch>();

  endDate: any; // fecha final
  startDate: any; // fecha inicial
  bpType: number; // tipo de BP
  bpStatus: number; // estado del socio de negocios
  DBcode: string;
  User : any;
  Passwd : string;
  currentUser: any;
  SelectedCompany: string;

  // lista con los tipos de socios de negocios
  bpTypeList: any[] = [
    {
      type: 1,
      name: 'Cliente',
    },
    // {
    //   type: 2,
    //   name: 'Proveedor',
    // }
  ];

  // lista con los estados de los socios de negocios
  bpStatusList: any[] = [
    {
      type: 0,
      name: 'Correcto',
    },
    {
      type: 3,
      name: 'Espera SAP',
    },  
    {
      type: 2,
      name: 'Ejecutándose',
    },  
    {
      type: 1,
      name: 'Error',
    }
  ];

  date: Date = new Date();

  constructor(private storage: StorageService) {
    this.endDate = `${this.date.getFullYear()}-${('0' + (this.date.getMonth() + 1)).slice(-2)}-${('0' + this.date.getDate()).slice(-2)}`;
    this.startDate = `${this.date.getFullYear()}-${('0' + (this.date.getMonth() + 1)).slice(-2)}-${('0' + this.date.getDate()).slice(-2)}`;
    this.bpType = this.bpTypeList[0].type;
    this.bpStatus = this.bpStatusList[0].type;    
    this.currentUser = JSON.parse(this.storage.getCurrentSession());
    this.User = this.currentUser.UserName;
    this.DBcode = this.currentUser.DbCode
    this.Passwd =JSON.parse(this.storage.getCurrentPass());
  }

  ngOnInit() {
    // this.currentUser = JSON.parse(this.storage.getCurrentSession());
    // this.SelectedCompany = JSON.parse(this.storage.getSelectedCompany());
    this.getBP();
  }

  // funcion para obtener los parametros para filtrado de los BP de la DBLocal
  public getBP(): BusinessPartnerSearch {
    let bp = new BusinessPartnerSearch(this.startDate, this.endDate, this.bpType, this.bpStatus, this.DBcode, this.Passwd, this.User );
    this.emitEvent.emit(bp);
    return bp;
  }



}
