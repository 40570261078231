import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CompanyconfigService {

  constructor(private http: HttpClient) {
    console.log('CompanyconfigService listo');
  }

  getQuery( ) {
    const URL = `https://misadapi.clavisco.com/api/Configs/GetSAPConfigs`;
    return this.http.get(URL);
  }

  getCampanyConfigs() {

    return this.getQuery();

    // return this.getQuery('browse/new-releases?limit=20')
      // .pipe( map( data => data['albums'].items ));

  }

}
