import { RouterModule, Routes } from '@angular/router';
import { BusinesspartnerComponent } from './components/businesspartner/businesspartner.component';
import { CompanyconfigComponent } from './components/companyconfig/companyconfig.component';
import { DocumentComponent } from './components/document/document.component';
import { HomeComponent } from './components/home/home.component';
import { SapconfigComponent } from './components/sapconfig/sapconfig.component';
import { LoginComponent} from './components/login/login.component';
import { AuthGuard } from './services/auth.guard';
import { RecoverEmailComponent } from './components/recover-email/recover-email.component';
import { from } from 'rxjs';

const APP_ROUTES: Routes = [ 
    { path: '', component: HomeComponent, canActivate:[AuthGuard]}, 
    { path: 'login', component: LoginComponent},  
    { path: 'businesspartner', component: BusinesspartnerComponent},
    { path: 'companyconfig', component: CompanyconfigComponent},
    { path: 'document', component: DocumentComponent},      
    { path: 'sapconfig', component: LoginComponent},
    { path: 'recoveremail/:email', component: RecoverEmailComponent },
    { path: '**', pathMatch: 'full', redirectTo: '' },
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES);
 