import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BusinessPartnerSearch } from '../models/bpsearch';
import { AppConstants } from '../models';

@Injectable({
  providedIn: 'root'
})
export class BusinesspartnerService {

  constructor(private http: HttpClient) {
    console.log('BusinesspartnerService listo');
  }

  getQuery( query: BusinessPartnerSearch ) {
    const URL =`${AppConstants.apiUrl}api/BusinessPartner/SearchBusinessPartners/`
    //const URL = `https://misadapitesting.clavisco.com/api/BusinessPartner/SearchBusinessPartners/`;
    return this.http.post(URL, query);
  }

  getBusinessPartner(bpSearch: BusinessPartnerSearch) {

    return this.getQuery(bpSearch);

    // return this.getQuery('browse/new-releases?limit=20')
      // .pipe( map( data => data['albums'].items ));

  }

}
