import { Component, OnInit } from '@angular/core';
import { CompanyconfigService } from '../../services/companyconfig.service';
import { Company } from '../../models/company';

@Component({
  selector: 'app-companyconfig',
  templateUrl: './companyconfig.component.html',
  styleUrls: ['./companyconfig.component.css']
})
export class CompanyconfigComponent implements OnInit {

  companyHaveData: boolean;
  companyMessage: string;

  companyList: Company[] = [];

  constructor( private companyconfig: CompanyconfigService ) {
    console.log('constructor CompanyconfigComponent');
    // this.sapConn = new SAPConnection(0, '', '', '', '', '', '', true);
  }


  ngOnInit() {
    console.log('ngOnInit CompanyconfigComponent');
    this.companyconfig.getCampanyConfigs()
    .subscribe( (data: any) => {
      console.log(data);
      this.companyHaveData = data.result;
      console.log(data.result);
      if (data.result) {
      console.log('t');

        this.companyList = data.CompanysList;
      } else {
      console.log('f');

        this.companyMessage = data.errorInfo.Message;
      }
    });
  }


}

