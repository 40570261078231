export class BusinessPartnerSearch {
    constructor(
        public StartDate: Date,
        public EndDate: Date,
        public TypeBP: number, // tipo de BP (Customer = 1, supplier = 2)
        public Status: number, // tipo de estado (Correcto = 0, Error = 1)
        public DBcode: string,
        public Passwd: string,
        public User: string,
    ) { }
}
